<template>
  
</template>
<script>
export default {
  name: 'wx-login',
  data () {
    return {
      hospitalId: '',
      redirect_uri: ''
    }
  },
  created () {
    this.hospitalId = this.$route.query.HospitalId
    this.redirect_uri = this.$route.query.redirect_uri
    if (this.redirect_uri) {
      console.log(this.redirect_uri)
    }
    const appId = process.env.VUE_APP_APPID
    const aliAppId = process.env.VUE_ALI_APPID
    console.log(appId);
    const originUrl = process.env.VUE_APP_BASEURL + '/#' + this.redirect_uri
    const redirect_uri = encodeURIComponent(originUrl)
    console.log(redirect_uri)
    const userAgent = navigator.userAgent
    let url
 
     // 支付宝
     if (userAgent.indexOf("AlipayClient") > -1){
      url = ` https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${aliAppId}&redirect_uri=${redirect_uri}&scope=auth_user`
    }
      else {
      url = ` https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base#wechat_redirect`
    }
    if (this.$store.state.openid) {
      if (this.redirect_uri) {
        this.$router.replace(this.redirect_uri)
      } else {
        this.$router.replace('/home')
      }
    } else {
      location.href = url
    }
  }
}
</script>